import 'babel-polyfill';
import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import './App.css';
import authProvider from './authProvider';
import sagas from './sagas';
import themeReducer from './themeReducer';
import Login from './Login';
import Layout from './Layout';
import Menu from './Menu';
import Dashboard from './dashboard/Dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import MyLayout from './MyLayout';

import {
  HomeSliderList,
  HomeSliderCreate,
  HomeSliderEdit,
  HomeSliderIcon,
} from './homeSlider';
import {
  GalleryList,
  GalleryCreate,
  GalleryEdit,
  GalleryIcon,
} from './gallery';
import {
  GiftSettingList,
  GiftSettingEdit,
  GiftSettingIcon,
} from './giftSetting';
import { ProductsList, ProductsEdit, ProductsIcon } from './products';
import {
  OrdersList,
  OrdersCreate,
  OrdersEdit,
  OrderShow,
  OrdersIcon,
} from './orders';
import {
  PendingOrdersList,
  PendingOrdersCreate,
  PendingOrdersEdit,
  PendingOrdersShow,
  PendingOrdersIcon,
} from './pending_orders';
import { TransactionsList, TransactionsIcon } from './transactions';
import {
  ProductsAdditionList,
  ProductsAdditionEdit,
  ProductsAdditionIcon,
} from './productsAddition';
import {
  ProductsAdditionCategoryList,
  ProductsAdditionCategoryEdit,
} from './productsAdditionCategory';
import {
  ProductCategoryList,
  ProductCategoryEdit,
  ProductCategoryIcon,
} from './productCategory';
import {
  UsersList,
  UsersCreate,
  UsersEdit,
  UserShow,
  UsersIcon,
} from './users';
import { UserPointsList, UserPointsIcon } from './UserPoints';
import { RedeemsList, RedeemsIcon } from './UserRedeems';
import { AreasList, AreasCreate, AreasEdit, AreasIcon } from './areas';
import {
  BranchesList,
  BranchesCreate,
  BranchesEdit,
  BranchesIcon,
} from './branches';
import { CitiesList, CitiesIcon, CitiesEdit } from './cities';
import { NotificationsList, NotificationsIcon } from './notifications';
import { PromoList, PromoCreate, PromoEdit, PromoIcon } from './promoCodes';
import { ContactUsList, ContactUsShow, ContactUsIcon } from './contactUs';
import createHistory from 'history/createBrowserHistory';
import dataProviderFactory from './dataProvider';

const messages = {
  en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

class App extends Component {
  state = { dataProvider: null };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }
  async componentWillMount() {
    this.checkAuth(this);
    this.checkAuth2(this);
    const dataProvider = await dataProviderFactory(
      process.env.REACT_APP_DATA_PROVIDER,
    );

    this.setState({ dataProvider });
  }

  componentWillUnmount() {
    this.restoreFetch();
  }

  checkAuth(that) {
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + '/api/checkToken',
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029m',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Access-Control-Allow-Origin': '*',
        }),
      },
    );
    return fetch(request)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (
          localStorage.getItem('token') &&
          myJson.error &&
          myJson.error === 'Unauthenticated.'
        ) {
          localStorage.clear();

          const h = createHistory();
          h.push('/#login', { some: 'state' });

          that.forceUpdate();
        }
      });
  }
  checkAuth2(that) {
    const request2 = new Request(
      process.env.REACT_APP_CLIENT_URL + '/api/products',
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029m',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Access-Control-Allow-Origin': '*',
        }),
      },
    );
    return fetch(request2)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        const myObjStr = JSON.stringify(myJson);
        localStorage.setItem('products', myObjStr);
      });
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <div className='loader-container'>
          <div className='loader'>Loading...</div>
        </div>
      );
    }
    if (
      localStorage.getItem('subAdmin') !== '1' &&
      localStorage.getItem('editorAdmin') !== 1
    ) {
      return (
        <Admin
          title='Crepe & Waffle Admin Panel'
          dataProvider={dataProvider}
          customReducers={{ theme: themeReducer }}
          customSagas={sagas}
          customRoutes={customRoutes}
          authProvider={authProvider}
          dashboard={Dashboard}
          loginPage={Login}
          // appLayout={Layout}
          appLayout={MyLayout}
          menu={Menu}
          locale='en'
          i18nProvider={i18nProvider}
        >
          <Resource
            name='transactions'
            list={TransactionsList}
            icon={TransactionsIcon}
          />
          <Resource
            name='homeSlider'
            list={HomeSliderList}
            create={HomeSliderCreate}
            edit={HomeSliderEdit}
            icon={HomeSliderIcon}
          />
          <Resource
            name='gallery'
            list={GalleryList}
            create={GalleryCreate}
            edit={GalleryEdit}
            icon={GalleryIcon}
          />
          <Resource
            name='giftSetting'
            list={GiftSettingEdit}
            edit={GiftSettingEdit}
            icon={GiftSettingIcon}
          />
          <Resource
            name='products'
            list={ProductsList}
            edit={ProductsEdit}
            icon={ProductsIcon}
          />
          <Resource
            name='productsAdditionCategory'
            edit={ProductsAdditionCategoryEdit}
            list={ProductsAdditionCategoryList}
          />
          <Resource
            name='productsAddition'
            list={ProductsAdditionList}
            edit={ProductsAdditionEdit}
            icon={ProductsAdditionIcon}
          />
          <Resource
            name='productCategory'
            list={ProductCategoryList}
            edit={ProductCategoryEdit}
            icon={ProductCategoryIcon}
          />
          <Resource
            name='users'
            list={UsersList}
            create={UsersCreate}
            edit={UsersEdit}
            show={UserShow}
            icon={UsersIcon}
          />
          <Resource
            name='usersPoints'
            list={UserPointsList}
            icon={UserPointsIcon}
          />
          <Resource name='usersRedeems' list={RedeemsList} icon={RedeemsIcon} />
          <Resource
            name='branches'
            list={BranchesList}
            create={BranchesCreate}
            edit={BranchesEdit}
            icon={BranchesIcon}
          />
          <Resource
            name='areas'
            list={AreasList}
            // create={AreasCreate}
            edit={AreasEdit}
            icon={AreasIcon}
          />
          <Resource
            name='cities'
            edit={CitiesEdit}
            list={CitiesList}
            icon={CitiesIcon}
          />
          <Resource
            name='promoCodes'
            list={PromoList}
            create={PromoCreate}
            edit={PromoEdit}
            icon={PromoIcon}
          />
          <Resource
            name='contactUs'
            list={ContactUsList}
            icon={ContactUsIcon}
            show={ContactUsShow}
          />
          <Resource
            name='orders'
            list={OrdersList}
            edit={OrdersEdit}
            show={OrderShow}
            icon={OrdersIcon}
          />
          <Resource
            name='pendingOrder'
            list={PendingOrdersList}
            edit={PendingOrdersEdit}
            show={PendingOrdersShow}
            icon={PendingOrdersIcon}
          />

          <Resource
            name='transactions'
            list={TransactionsList}
            icon={TransactionsIcon}
          />
          <Resource
            name='notifications'
            list={NotificationsList}
            icon={NotificationsIcon}
          />
        </Admin>
      );
    } else if (localStorage.getItem('editorAdmin') === 1) {
      return (
        <Admin
          title='Crepe & Waffle Admin Panel'
          dataProvider={dataProvider}
          customReducers={{ theme: themeReducer }}
          customSagas={sagas}
          customRoutes={customRoutes}
          authProvider={authProvider}
          dashboard={Dashboard}
          loginPage={Login}
          appLayout={Layout}
          menu={Menu}
          locale='en'
          i18nProvider={i18nProvider}
        >
          <Resource
            name='users'
            list={UsersList}
            create={UsersCreate}
            edit={UsersEdit}
            show={UserShow}
            icon={UsersIcon}
          />
        </Admin>
      );
    } else {
      return (
        <Admin
          title='Crepe & Waffle Admin Panel'
          dataProvider={dataProvider}
          customReducers={{ theme: themeReducer }}
          customSagas={sagas}
          customRoutes={customRoutes}
          authProvider={authProvider}
          dashboard={Dashboard}
          loginPage={Login}
          appLayout={Layout}
          menu={Menu}
          locale='en'
          i18nProvider={i18nProvider}
        >
          <Resource
            name='homeSlider'
            list={HomeSliderList}
            create={HomeSliderCreate}
            edit={HomeSliderEdit}
            icon={HomeSliderIcon}
          />
          <Resource
            name='gallery'
            list={GalleryList}
            create={GalleryCreate}
            edit={GalleryEdit}
            icon={GalleryIcon}
          />
          <Resource
            name='giftSetting'
            list={GiftSettingEdit}
            edit={GiftSettingEdit}
            icon={GiftSettingIcon}
          />
          <Resource
            name='products'
            list={ProductsList}
            edit={ProductsEdit}
            icon={ProductsIcon}
          />
          <Resource
            name='productsAdditionCategory'
            edit={ProductsAdditionCategoryEdit}
            list={ProductsAdditionCategoryList}
          />
          <Resource
            name='productsAddition'
            list={ProductsAdditionList}
            edit={ProductsAdditionEdit}
            icon={ProductsAdditionIcon}
          />
          <Resource
            name='productCategory'
            list={ProductCategoryList}
            edit={ProductCategoryEdit}
            icon={ProductCategoryIcon}
          />
          <Resource
            name='users'
            list={UsersList}
            show={UserShow}
            icon={UsersIcon}
          />
          <Resource
            name='usersPoints'
            list={UserPointsList}
            icon={UserPointsIcon}
          />
          <Resource name='usersRedeems' list={RedeemsList} icon={RedeemsIcon} />
          <Resource
            name='branches'
            list={BranchesList}
            create={BranchesCreate}
            edit={BranchesEdit}
            icon={BranchesIcon}
          />
          <Resource
            name='areas'
            list={AreasList}
            // create={AreasCreate}
            edit={AreasEdit}
            icon={AreasIcon}
          />
          <Resource name='cities' list={CitiesList} icon={CitiesIcon} />

          <Resource
            name='contactUs'
            list={ContactUsList}
            icon={ContactUsIcon}
            show={ContactUsShow}
          />
          <Resource
            name='orders'
            list={OrdersList}
            edit={OrdersEdit}
            show={OrderShow}
            icon={OrdersIcon}
          />
        </Admin>
      );
    }
  }
}

export default App;
